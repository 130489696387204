import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Form,
  Row,
  Col,
} from "reactstrap";

import InputField, { fields, parseDate } from './InputField';

function MainPage() {
  const [isLoading, setloading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  function submit() {
    setloading(true);
  
    if(fields.some(f => !f.validate((document.getElementById('input-' + f.id) as HTMLInputElement).value))) {
      return setloading(false);
    }

    const data: Record<string, string> = {};
    for(const field of fields) {
      data[field.id] = (document.getElementById('input-' + field.id) as HTMLInputElement).value;
    }
    data.birth = parseDate(data['birth'])!;
    if(!data.birth) return setloading(false);
    data.price = Number(data.price.replace(',', '.').replace('€ ', '')).toFixed(2);
    data.phone = data.phone.replace('+', '00');

    const url = process.env.NODE_ENV === 'production' ? 'https://cadettencorps.nl/api/vrienden/' : 'http://localhost/api.php';
    return fetch(url, { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } })
      .then(res => res.json())
      .then(({ error }) => {
        setError(Boolean(error));
        if(error) console.log(error);
        else setSuccess(true);
      })
      .catch(e => {
        console.log(e);
        return setError(true);
      })
      .finally(() => setloading(false))
  }
  
  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/images/background.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col>
                <div className="info info-horizontal" style={{ maxWidth: 600 }}>
                  <div className="description">
                    <div className="icon icon-info" style={{ marginRight: 30 }}>
                      <i className="now-ui-icons travel_info"></i>
                    </div>
                    <h5 className="info-title">Welkom op de website voor Vrienden van het Cadettencorps</h5>
                    <p className="description" style={{ opacity: 1, marginLeft: 45 }}><b>
                      Vrienden van het Cadettencorps is een initiatief voor oud-Corpsleden die graag in contact willen blijven met de vereniging en het Corps een hart onder de riem willen steken door middel van een kleine financiële bijdrage naar keuze.
                      Er zal jaarlijks een borrel georganiseerd worden waarbij Vrienden van het Cadettencorps terug kunnen komen op de plek waar het allemaal begonnen is. Hier kunnen Vrienden elkaar ontmoeten en bijpraten onder het genot van een hapje en een drankje. Ook zullen er andere eenmalige bedankjes zijn, bijvoorbeeld verloting van kaartjes voor het Assaut.
                      Lijkt het u leuk om Vriend van het Cadettencorps te worden? Meldt u dan hier aan!
                    </b></p>
                  </div>
                </div>
                <div className="info info-horizontal" style={{ maxWidth: 600 }}>
                  <div className="description">
                    <div className="icon icon-info" style={{ marginRight: 30 }}>
                      <i className="fas fa-glass-cheers"></i>
                    </div>
                    <h5 className="info-title">Volgende Vriendenborrel op 17 juni 2022</h5>
                  </div>
                </div>
                <div className="info info-horizontal" style={{ maxWidth: 600 }}>
                  <div className="description">
                    <div className="icon icon-info" style={{ marginRight: 30 }}>
                      <i className="fab fa-instagram"></i>
                    </div>
                    <a href="https://www.instagram.com/cadettencorps/" style={{ color: 'white' }}><h5 className="info-title">Volg het Cadettencorps op Instagram</h5></a>
                  </div>
                </div>
                <div className="info info-horizontal" style={{ maxWidth: 600 }}>
                  <div className="description">
                    <div className="icon icon-info" style={{ marginRight: 30 }}>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </div>
                    <a href="mailto:Senaat.KMA@mindef.nl" style={{ color: 'white' }}><h5 className="info-title">Senaat.KMA@mindef.nl</h5></a>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" md="6" lg="4">
                <Card className="card-signup">
                  <CardBody>
                    <CardTitle className="text-center" tag="h4">
                      Aanmelden
                    </CardTitle>
                    <Form action="" className="form" method="">
                      {fields.map((props, i) => <InputField key={`input${i}`} {...props} />)}
                      <CardFooter className="text-center">
                        {error && (
                          <Button
                            color="danger"
                            size="lg"
                            onClick={e => e.preventDefault()}
                          >
                            Er is helaas iets mis gegaan.
                          </Button>
                        )}
                        <Button
                          className="btn-round"
                          color={isSuccess ? "success" : "info"}
                          onClick={() => submit()}
                          size="lg"
                          disabled={isLoading || isSuccess}
                        >
                          {isLoading ? 'Loading...' : isSuccess ? 'U bent aangemeld!' : 'Aanmelden'}
                        </Button>
                      </CardFooter>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
          <footer className="footer">
          <Container>
            <div className="copyright" id="copyright">
              © {new Date().getFullYear()}, Het Cadettencorps
            </div>
          </Container>
        </footer>
      </div>
    </>
  );
}

export default MainPage;
