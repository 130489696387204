const algemeen = `
Het Cadettencorps is de vereniging voor en door de cadetten van de Koninklijke Militaire Academie (KMA).

Het Cadettencorps is in 1879 ontstaan met de Cadettensociëteit op de Grote Markt van Breda, opgericht voor cadetten om de zware tucht op de academie te ontlopen. Het doel was toen om hiermee bij te dragen aan de saamhorigheid en kameraadschap onder de leden en gelegenheid te bieden voor ontspanning en recreatie. Het jaar 1898 wordt als officieel oprichtingsjaar beschouwd, omdat vanaf toen de Senaat (bijna) onafgebroken als hoogste orgaan van het Cadettencorps heeft gefunctioneerd.

In de loop der tijd kreeg de vereniging ook ten doel de cadetten voor te bereiden op het officiersschap en creëerde het een veilige leeromgeving voor de leden, om te werken aan leidinggevende- en organisatorische vaardigheden. Met deze doelstellingen speelt het Cadettencorps een grote rol in de persoonsvorming op de KMA, naast de militaire- en de academische vorming van de aspirant-officieren.
`.trim();

const informatie = `
Het Cadettencorps is, sinds haar officiële oprichting in 1898, de vereniging voor en door aspirant-officieren (cadetten) van de Koninklijke Landmacht, Koninklijke Luchtmacht en Koninklijke Marechaussee gehuisvest op de Koninklijke Militaire Academie te Breda. Het Cadettencorps zorgt voor de nodige ontspanning, het brengt saamhorigheid en kameraadschap onder de leden en brengt hen de waarden en normen bij die zij moeten bezitten als officier in de Nederlandse Krijgsmacht. Bovendien biedt het Corps de mogelijkheid mensen, zowel intern als extern, te leren kennen. Daarnaast biedt het Corps de cadet de gelegenheid bestuursfuncties te vervullen om zo hun organisatorische en leidinggevende capaciteiten te ontwikkelen. Het Cadettencorps vervult verder tegenwoordig een belangrijke rol in de persoonsvorming tot officier, vandaar dat het, in tegenstelling tot toen het werd opgericht, samenwerkt en verbonden is met de opleiding aan de KMA.

Het Cadettencorps is samengesteld uit een Senaat, middenkaderverenigingen, Senaatscommissies en onderverenigingen.

De Senaat is het hoogste bestuursorgaan van het Cadettencorps. De Senaat is verantwoordelijk voor het dagelijkse bestuur van het Corps en houdt zich voornamelijk bezig met beleid, zowel op korte als lange termijn. De algemene taken van de Senaat zijn onder andere het organiseren van Corps-brede activiteiten en het behartigen van de belangen van het Cadettencorps en haar leden zowel intern als extern. Verder houdt zij onder andere de planning en  financiën bij, plus vervult zij een belangrijke rol met de Chef du Protocol in het bewaken en behouden van tradities, waarden en normen.

Voorbeelden van andere besturen binnen het Corps zijn: het Cadetten Sociëteitsbestuur, welke onder andere de Corpsruimtes beheert, de Sportcommissie, die zorg draagt voor alle sportverenigingen, At World's End, belangenbehartiger voor de cadetten studerend in Den Helder en de Assautcommissie, die jaarlijks het oudste en traditionele cadettenfeest organiseert.

Naast de Senaat en haar verenigingen kent het Corps een systeem van Corpsfamilies die vele jaren terug gaan, waarin nieuwe Corpsleden in een familie komen van zowel cadetten als alumni van het Cadettencorps en de KMA.
`.trim();

const organisatie = `
Het Cadettencorps is samengesteld uit een Senaat, middenkaderverenigingen, Senaatscommissies en onderverenigingen.
`.trim();

const senaat = `
De Senaat is het hoogste bestuursorgaan van het Cadettencorps. De Senaat is verantwoordelijk voor het dagelijkse bestuur van het Corps en houdt zich voornamelijk bezig met beleid, zowel op korte als lange termijn. De algemene taken van de Senaat zijn onder andere het organiseren van Corps-brede activiteiten en het behartigen van de belangen van het Cadettencorps en haar leden zowel intern als extern. Verder houdt zij onder andere de planning en  financiën bij, plus vervult zij een belangrijke rol met de Chef du Protocol in het bewaken en behouden van tradities, waarden en normen.

De Senaat bestaat uit een Voorzitter, Secretaris, Penningmeester, het 1e Lid, het 2e Lid en een Chef du Protocol. 
`.trim();

const mikaves = `
Onder de Senaat vallen de middenkaderverenigingen. Deze verenigingen zijn verantwoordelijk het uitvoeren en uitdragen van het door de Senaat gestelde beleid. Zij hebben een sturende rol binnen het Corps. Voorbeelden van middenkaderverenigingen binnen het Corps zijn: het Cadetten Sociëteitsbestuur, welke onder andere de Corpsruimtes beheert, de Sportcommissie, die zorg draagt voor alle sportverenigingen, At World's End, belangenbehartiger voor de cadetten studerend in Den Helder en de Assautcommissie, die jaarlijks het oudste en traditionele cadettenfeest organiseert.
`.trim();

const onves = `
Verder zijn er nog onderverenigingen. Deze vallen onder de middenkaders. Zij zijn dagelijks actief binnen het leven op de KMA.

Naast de Senaat en haar verenigingen kent het Corps een systeem van Corpsfamilies die vele jaren terug gaan, waarin nieuwe Corpsleden in een familie komen van zowel cadetten als alumni van het Cadettencorps en de KMA.
`.trim();

const geschiedenis = `
Op 23 november 1879 vond de opening van de Cadettensociëteit aan de Grote Markt 17 in Breda plaats. Door de strenge tucht die heerste op de KMA hadden de cadetten behoefte aan een ruimte buiten de KMA om te kunnen ontspannen. Er volgde een periode van verschillende besturen van de sociëteit en al snel ontstond een Senaat. De Senaat zou, evenals als de Senaten van de (studenten-) corpora, de sociëteit en alle activiteiten van cadetten aansturen. Niet alle Senaten hielden stand maar in 1898 werd voor de derde keer een Senaat gekozen en deze had succes. Het jaar 1898 wordt dan ook gezien als het oprichtingsjaar van het Cadettencorps. De sociëteit in de stad is verdwenen en het Corps heeft nu eigen ruimtes op de KMA. Ook het karakter van het Corps is sinds die tijd veranderd. Nu staat naast gezelligheid het ontwikkelen van leidinggevende en organisatorische capaciteiten en planningsvaardigheden centraal. Daarnaast is het een voedingsbodem van contacten, die in het latere functioneren als officier onmisbaar zijn. Het Corps is daarom essentieel tijdens de opleiding voor de cadetten die later als officier in de Koninklijke Land- of Luchtmacht dan wel de Koninklijke Marechaussee gaan functioneren.
`.trim();

export { algemeen, informatie, organisatie, senaat, mikaves, onves, geschiedenis }
