import React, { RefObject } from "react";

// reactstrap components
import { Container } from "reactstrap";

function Header({ title }: { title: string }) {
  let pageHeader: RefObject<HTMLDivElement> = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if(pageHeader.current) {
          pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
        }
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small" style={{ maxHeight: 800 }}>
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/images/banner.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
            {window.screen.width < 310
             ? <h3 className="title">{title}</h3>
             : window.screen.width < 450
             ? <h2 className="title">{title}</h2>
             : <h1 className="title">{title}</h1>
            }
          </Container>
        </div>
      </div>
    </>
  );
}

export default Header;
