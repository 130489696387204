import React from 'react';

import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

export interface InputFieldProps {
  icon: string,
  id: string,
  placeholder: string,
  validate: (str: string) => boolean,
  type?: 'text' | 'date' | 'price'
}

export const fields: InputFieldProps[] = [
  {
    icon: 'users_circle-08',
    id: 'name',
    placeholder: 'Volledige Naam...',
    validate: s => /^.{3,} .{3,}$/i.test(s)
  },
  {
    icon: 'ui-1_email-85',
    id: 'email',
    placeholder: 'Email...',
    validate: s => s.includes('@') && s.includes('.')
  },
  {
    icon: 'tech_mobile',
    id: 'phone',
    placeholder: 'Telefoonnummer...',
    validate: s => /^\+?\d{9,14}$/i.test(s)
  },
  {
    icon: 'ui-1_calendar-60',
    id: 'opkomstjaar',
    placeholder: 'Opkomstjaar...',
    validate: s => !isNaN(Number(s)) && Number(s) > 1900 && Number(s) < new Date().getFullYear()
  },
  {
    icon: 'ui-1_calendar-60',
    id: 'birth',
    placeholder: 'Geboortedatum ...',
    validate: s => parseDate(s) !== null,
    type: 'date'
  },
  {
    icon: 'shopping_credit-card',
    id: 'IBAN',
    placeholder: 'IBAN...',
    validate: s => isValidIBANNumber(s)
  },
  {
    icon: 'business_money-coins',
    id: 'price',
    placeholder: '€ Bedrag per maand...',
    type: 'price',
    validate: s => {
      const amount = Number(s.slice(2).replace(',', '.'));
      return !isNaN(amount) && amount > 0 && Number(amount.toFixed(2)) === amount;
    }
  }
]

export function parseDate(s: string) {
  let day;
  let month;
  let year;

  const el = s.split('-');
  if(el.length === 3) {
    day = el[0].padStart(2, '0');
    month = el[1].padStart(2 ,'0');
    year = el[2].length === 4 ? el[2] : '19' + el[2];
  } else {
    day = s.slice(0, 2);
    month = s.slice(2, 4);
    year = s.length === 8 ? s.slice(4) : '19' + s.slice(4);
  }

  if(day.length !== 2 || !Number(day) || Number(day) > 31) return null;
  if(month.length !== 2 || !Number(month) || Number(month) > 12) return null;
  if(year.length !== 4 || !Number(year) || Number(year) < 1900 || Number(year) > new Date().getFullYear()) return null;
  return `${year}-${month}-${day}`;
}

function InputField(props: InputFieldProps) {
  const [focus, setFocus] = React.useState(false);
  const [isInValid, setInValid] = React.useState(false);
  const [isUsed, setUsed] = React.useState(false);
  
  const onUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valid = props.validate(e.target.value);
    if(valid === isInValid) setInValid(!valid);
  }

  return (
    <InputGroup
      className={focus ? "no-border input-group-focus" : "no-border"}
    >
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className={`now-ui-icons ${props.icon}`}></i>
        </InputGroupText>
      </InputGroupAddon>
      <Input
        style={{ boxShadow: 'none' }}
        id={'input-' + props.id}
        autoComplete={props.id}
        placeholder={props.placeholder}
        type="text"
        onFocus={() => setFocus(true)}
        onBlur={e => { setFocus(false); if(!isUsed) { setUsed(true); onUpdate(e); } }}
        onChange={e => {
          if(props.type === 'price' && !e.target.value.startsWith('€ ')) {
            if(e.target.value.startsWith('€')) {
              e.target.value = e.target.value.replace('€', '€ ');
            } else {
              e.target.value = '€ ' + e.target.value;
            }
          }
          isUsed && onUpdate(e);
        }}
        required
        invalid={isInValid}
      ></Input>
    </InputGroup>
  )
}

export default InputField;

function isValidIBANNumber(input: string) {
  var CODE_LENGTHS: Record<string, number> = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
      AL: 28, BY: 28, XK: 20, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24
  }
  var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
    digits;

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) return false;
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => String(letter.charCodeAt(0) - 55));
  var checksum: any = digits.slice(0, 2), fragment;
  for (var offset = 2; offset < digits.length; offset += 7) {
      fragment = String(checksum) + digits.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
  }
  return checksum === 1;
}
