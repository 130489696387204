import { Container } from "reactstrap";

function Footer() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Cadettencorps
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
