import React from "react";

import * as CONTENT from './content';

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/ScrollTransparentNavbar";
import Header from "components/Header";
import Footer from "components/Footer";
import InstaNews from "./InstaNews";

function MainPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar
        items={[
          { title: 'Nieuws', link: 'nieuws' },
          { title: 'Informatie', link: 'informatie' },
          { title: 'Organisatie', link: 'organisatie' },
          { title: 'Geschiedenis', link: 'geschiedenis' },
          //{ title: 'Assaut', link: '/assaut' },
          { title: 'Vrienden', link: '/vrienden' },
          { title: 'Contact', link: 'contact' }
        ]}
        offset={60}
      />
      <div className="wrapper">
        <Header title="Cadettencorps" />
        <div className="about-description text-center">
          <Container>
            <Row id="nieuws">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Nieuws</h2>
              </Col>
            </Row>
            <InstaNews />
            <Row id="informatie">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Wat is het Cadettencorps</h2>
                {CONTENT.algemeen.split('\n\n').map((t, i) => <p id={`AlgC-${i}`}>{t}</p>)}
              </Col>
            </Row>
            <div className="section-story-overview" id="organisatie">
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">De organisatie structuur</h2>
                  <p>
                    {CONTENT.organisatie}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      marginBottom: 0,
                      backgroundImage:
                        "url(" + require("assets/images/senaat.jpg").default + ")",
                    }}
                  >
                  </div>
                  <div style={{ margin: 50 }}>
                    <h4>Senaat</h4>
                    <p>{CONTENT.senaat}</p>
                  </div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/images/mikave.jpg").default + ")",
                    }}
                  ></div>
                  <h4>Middenkaderverenigingen</h4>
                  <p>{CONTENT.mikaves}</p>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/images/onve.jpg").default + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <h4>Onderverenigingen</h4>
                  <p>{CONTENT.onves}</p>
                </Col>
              </Row>
              <Row id="geschiedenis">
                <Col md="8">
                  <h4>Geschiedenis</h4>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <p>{CONTENT.geschiedenis}</p>
                </Col>
                <Col md="3">
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      padding: 'auto',
                      backgroundImage:
                        "url(" + require("assets/images/geschiedenis.jpg").default + ")",
                    }}
                  ></div>
                </Col>
              </Row>
            </div>
            <Row id="contact">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Contact</h2>
                <p>
                  Het Cadettencorps is gevestigd aan kasteelplein 10, 4811XC Breda.<br />
                  Mocht u nog vragen hebben over het Cadettencorps? Neem dan contact op met de Senaat van het Cadettencorps via Senaat.KMA@mindef.nl.
                </p>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MainPage;
