import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

interface InstaItem {
  id: string,
  title: string,
  image: string,
  permalink:	string,
  timestamp: string,
  date: Date // Property not received from API
}

interface InstaNewsState {
  data?: InstaItem[],
  error: boolean
}

class InstaNews extends React.Component {
  state: InstaNewsState = { data: undefined, error: false }

  async componentDidMount() {
    try {
      const res = process.env.NODE_ENV === 'development'
        ? await fetch('http://localhost:80/')
        : await fetch('https://cadettencorps.nl/api/', { headers: { Authorization: 'Basic ' + btoa('guest:cadettencorps') }});
      const data: InstaItem[] = await res.json();
      if((data as any).error) return this.setState({ error: true });
      data.forEach(e => e.date = new Date(e.timestamp));
      this.setState({ data });
    } catch(e) {
      console.error(e);
      return this.setState({ error: true });
    }
  }
  
  render() {
    const { data, error } = this.state;
    if(error) {
      return (
        <p style={{ textAlign: "center" }}>Er is helaas iets misgegaan tijdens het ophalen van recente nieuws posts. Probeer het later opnieuw.</p>
      )
    }
    if(!data) {
      return (
        <p style={{ textAlign: "center" }}>Aan het laden...</p>
      )
    }
    return (
      <div className="blogs-2" id="blogs-2">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Row className="justify-content-center">
                {data.map((data, i) => (
                  <Col md="4" key={i}>
                    <Card className="card-plain card-blog">
                      <div className="card-image">
                        <img
                          onClick={() => window.open(data.permalink)}
                          alt={data.title}
                          className="img img-raised rounded"
                          src={data.image}
                          style={{ cursor: 'pointer' }}
                        ></img>
                      </div>
                      <CardBody>
                        <h5 className="category">
                          <a href={data.permalink} target="_blank" rel="noreferrer">{data.title.slice(0, 100)}</a>
                        </h5>
                        <h6 className="category">Geplaatst op Instagram - {`${data.date.getDate()} ${["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"][data.date.getMonth()]} ${data.date.getFullYear()}`}</h6>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default InstaNews;
